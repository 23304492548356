<template>
  <div
    ref="navigation"
    class="navigation position-relative"
    :class="
      `${
        isShowMessages || isShowManyMessages
          ? 'navigation-notify-height'
          : isShowMessages || isShowManyListedMessages
            ? 'navigation-many-listed-height'
            : listed
              ? 'navigation-listed-height'
              : isShowOneMessage
                ? 'navigation-full-height'
                : 'navigation-height'
      }`
    "
  >
    <custom-dialog :isDialogOpen="isDialogOpen" @close="closeDialog" @save="saveOrUpdateTempData"/>
    <!-- Welcome message -->
    <div class="welcome-message mt-3">
      <p class="font-weight-bold fs-24">
        Welcome back, {{ authenticateData.customerName }}!
      </p>
    </div>
    <!-- Rego and car details -->
    <div 
      class="fs-20 font-weight-bold text-left"
      style="margin-bottom: 16px;"
    >
      {{ authenticateData.rego }} | {{ authenticateData.year }}
      {{ authenticateData.make.toUpperCase() }}
      {{ authenticateData.model.toUpperCase() }}
    </div>
    <!-- Listing status -->
    <div
      v-if="!authenticateData.readonlyMode && !isNZ"
      class="listing-badge mb-3"
      :class="`${listingStatus.toLowerCase().replace(' ', '-')}`"
    >
      {{ listingStatus }}
      <div class="listing-status-tooltip">{{ listingStatusTooltip }}</div>
    </div>
    <!-- Error message -->
    <div 
      ref="navigation-err-msg" 
      class="navigation-err-container" 
    >
      <div
        v-if="initValid && isSomePageNotValid && isShowErrMsg" 
        class="navigation-err-msg d-flex align-items-center justify-content-between px-4 mb-4"
      >
        <div class="navigation-err-text d-flex justify-content-center align-content-center">
          <img src="@/assets/images/icons/ic_incomplete.svg">
          <span class="font-weight-thin ml-2">Incomplete details. Complete the section(s) to continue.</span>
        </div>
        <img src="@/assets/images/icons/ic_close_msg.svg" class="navigation-err-img cursor-pointer" @click="handleCloseErrMsg">
      </div>
    </div>
    <!-- Unsaved message -->
    <div v-if="isShowSaveDraftMsg || isShowConfirmMsg" ref="navigation-unsaved-msg" class="navigation-unsaved-container">
      <div v-if="isShowSaveDraftMsg" class="navigation-unsaved-msg d-flex align-items-center justify-content-between px-4 mb-4">
        <span class="font-weight-thin">You have unsaved changes. Please remember to <span class="font-weight-regular">'Save draft'</span> before you leave.</span>
        <img src="@/assets/images/icons/ic_close_msg.svg" class="cursor-pointer" @click="handleCloseUnsavedMsg">
      </div>
      <div v-if="isShowConfirmMsg" class="navigation-unsaved-msg d-flex align-items-center justify-content-between px-4 mb-4">
        <span class="font-weight-thin">You have unsaved changes. Please remember to <span class="font-weight-regular">'SUBMIT'</span> to finalise your changes.</span>
        <img src="@/assets/images/icons/ic_close_msg.svg" class="cursor-pointer" @click="handleCloseUnsavedConfirmMsg">
      </div>
    </div>
    <!-- Page components -->
    <ul class="navigation-list pl-0">
      <li v-for="page in pages" :key="page.title" @click="handleEditMobilePage(page.pageName)" 
        class="navigation-item py-5 d-flex align-items-center justify-content-between"
        :class="[
          !page.isCompleted && page.pageName !== 'photo-upload' ? 'navigation-item-uncompleted-height' : '',
          initValid && !checkReadonly && (
            (!page.isCompleted && page.pageName !== 'photo-upload') || 
            (!isPhotoUploadValid() && page.pageName === 'photo-upload')
          ) ? 'navigation-item-uncompleted' : '',
        ]"
      >
        <div class="navigation-info">
          <p class="navigation-page-title fs-20 mb-0">{{ page.title }}</p>
          <p class="navigation-page-desc fs-16 mb-0 mt-2 font-weight-regular">{{ page.description }}</p>
          <div v-if="page.isCompleted" class="navigation-page-status d-flex align-items-center mt-2">
            <img src="@/assets/images/icons/ic_checked.svg">
            <p class="fs-16 mb-0 ml-2">{{ page.isCompleted ? 'Complete' : '' }}</p>
          </div>
          <div v-if="page.pageName === 'photo-upload' && !isPhotoSectionCompleted()" class="navigation-page-photos-tile d-flex align-items-center mt-2">
            <img src="@/assets/images/icons/ic_photos_tile.svg">
            <p class="fs-16 font-weight-regular mb-0 ml-2" :class="isMobile ? 'fs-14' : 'fs-16'">At least 10 photos required</p>
          </div>
        </div>
        <button v-if="!isMobile" class="navigation-edit-btn" @click="handleEditPage(page.pageName)">{{ checkReadonly ? 'View' : 'Edit' }}</button>
        <button v-else class="navigation-edit-btn">
          <img src="@/assets/images/icons/ic_navigation.svg">
        </button>
      </li>
    </ul>
    <!-- Report Issue -->
    <div v-b-modal.modal-issue class="navigation-report d-flex justify-content-center align-items-center">
      <img class="mr-2" src="@/assets/images/icons/ic_report.svg">
      <p class="font-weight-regular mb-0">Report an issue</p>
    </div>
    <!-- Checkboxes -->
    <div v-if="authenticateData.leadSource !== 'lp007'" :style="isNZ ? 'padding-bottom: 80px' : 'padding-bottom: 60px'">
      <div class="seller-customer mt-4">
        <p class="mb-0">
          <b>I agree to:</b>
        </p>
        <div
          v-if="isActiveSellerCheckbox" 
          class="seller-checkbox font-weight-thin mt-1"
        >
          <label for="sellerAgree" class="seller-checkbox-label" @click="showSellerTermTooltip" @mouseleave="hideSellerTermTooltip">
            <input 
              type="checkbox" 
              id="sellerAgree" 
              :disabled="isAgreed || checkReadonly" 
              v-model="saleInfo.sellerTermsAndFees"
            >
            <CheckIcon v-if="saleInfo.sellerTermsAndFees" :class="`${(isAgreed || checkReadonly) ? 'readonly-checkbox' : ''}`" />
            <span v-html="feesAgreementsCheckboxContent"></span>
            <!-- Seller terms and fees tooltip -->
            <div class="seller-term-tooltip" v-if="isShowSellerTermTooltip">
              You have already agreed to our Seller Agreement and Success Fee.
            </div>
          </label>
        </div>
        <span v-if="sellerTermsAndFeesError && showTermsAndFeesMessage" class="seller-checkbox-err">
          Please check the box to proceed.
        </span>
        <div
          class="seller-checkbox font-weight-thin"
        >
          <label for="marketingComm" class="seller-checkbox-label">
            <input type="checkbox" id="marketingComm" v-model="saleInfo.marketingComm" :disabled="checkReadonly">
            <CheckIcon v-if="saleInfo.marketingComm" :class="`${checkReadonly ? 'readonly-checkbox' : ''}`" />
            <span>
              {{ marketingCheckbox }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- Button group -->
    <btn-group
      @onSubmit="handleConfirm"
      @onSave="openDialog"
      @showTooltip="showTooltip"
      @hideTooltip="hideTooltip"
      submitText="Submit"
      :notValid="!activeBtnAction || (checkReadonly && !onEdit)"
      :isMobile="isMobile"
      :isLoadingApi="isLoadingApi"
      :showDisableSubmitBtnTooltip="showDisableSubmitBtnTooltip"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  requiredIf,
} from "vuelidate/lib/validators";
import {
  SET_SALE_INFORMATION,
  UPDATE_DATA,
  SET_WRITTEN_OFF_AND_STOLEN,
} from "@/store/authenticate-seller/actions";
import { eraseCookie } from "@/helper/cookie";
import LeadUpdateService from "@/services/lead-update";
import {
  removeFormatNumberWithComma,
  separatesUploadImages,
  locationValMap,
  logError,
  LeadSourceStringMap,
} from "../../helper/utils";
import customerDetailService from "@/services/customer-detail";
import { browserList } from "@/helper/constant";
import { mandatoryList } from '@/config/constants';
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
export default {
  name: "Index",
  data() {
    return {
      isMobile: true,
      isAgreed: false,
      showTermsAndFeesMessage: false,
      isDialogOpen: false,
      isLoadingApi: false,
      writeOffAndStolenStatus: false,
      initValid: false,
      isSomePageNotValid: false,
      isShowErrMsg: false,
      isSpecialDeviceBrowser: false,
      isShowUnsavedMsg: false,
      isFirstTimeVisitPage: false,
      isShowUnsavedConfirmMsg: false,
      carInfoWhiteList: ['badge', 'badgeEditions', 'edition', 'model_year', 'buildDate', 'relevantRBDesc', 'shift'],
      carInfoNZWhiteList: ['location', 'badgeEdition', 'series', 'engineType', 'engineSize', 'numberOfSeat', 'badge', 'badgeEditions', 'edition', 'model_year', 'buildDate', 'relevantRBDesc', 'shift'],
      conditionInfoWhiteList: ['warranty', 'registrationStatus', 'tyreReplacement', 'description', 'serviceHistoryNotes', 'warningLightsDesc'],
      conditionInfoNZWhiteList: ['serviceHistoryDetail', 'key', 'ownerManual', 'warranty', 'registrationStatus', 'tyreReplacement', 'description', 'serviceHistoryNotes', 'warningLightsDesc'],
      saleInfoWhiteList: ['platform', 'pickUpLater', 'pickUp', 'marketingComm', 'isSubscribedToMailchimp', 'sellerTermsAndFees', 'productTier'],
      saleInfoNZWhiteList: ['advertised', 'platform', 'pickUpLater', 'pickUp', 'marketingComm', 'isSubscribedToMailchimp', 'sellerTermsAndFees', 'productTier'],
      personalInfoList: ['customerName', 'email', 'customerMobile', 'customerPostalCode'],
      nameRegex: /^[a-zA-Z0-9 ]+$/,
      emailRegex: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
      isShowManyMessages: false,
      isShowManyListedMessages: false,
      ipAddress: '',
      showDisableSubmitBtnTooltip: false,
      isShowSellerTermTooltip: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'top-page') {
      localStorage.removeItem('hasVisitedPage');
    }
    next();
  },
  components: {
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    CheckIcon: () => import("../../components/custom-svg/CheckIcon.vue"),
    CustomDialog: () => import("../../components/common/CustomDialog"),
  },
  computed: {
    ...mapState({
      authenticateData: (state) => state.authenticateSeller,
      carInfo: (state) => state.authenticateSeller.carInfo,
      conditionInfo: (state) => state.authenticateSeller.conditionInfo,
      saleInfo: (state) => state.authenticateSeller.saleInfo,
      uploadImage: (state) => state.photo.uploadImage,
      feesAgreementsCheckbox: (state) => state.commonVariables.copyBlocks.feesAgreementsCheckbox,
      marketingCheckbox: (state) => state.commonVariables.copyBlocks.marketingCheckbox,
      onEdit: (state) => state.common.onEdit,
      takePhotoWithGuidedCamera: (state) => state.photo.takePhotoWithGuidedCamera,
      uxeId: (state) => state.commonVariables.uxeId,
      listed: (state) => state.authenticateSeller.listed,
      guideCameraUsed: (state) => state.photo.guideCameraUsed,
      onEditPage: (state) => state.common.onEditPage,
      packages: (state) => state.commonVariables.packages,
      listingTiers: (state) => state.commonVariables.listingTiers,
      listingStatus: (state) => state.authenticateSeller.listingStatus,
      listingTiersExcludedLeadSourceList: (state) => state.commonVariables.listingTiersExcludedLeadSourceList,
      isIdle: (state) => state.common.isIdle,
    }),
    pages() {
      return [
        {
          title: "Car information",
          description: "General information about your car",
          isCompleted: this.isCarInfoValid(),
          pageName: "vehicle-info",
        },
        {
          title: "Condition",
          description: "Specifics about your car's condition",
          isCompleted: this.isConditionInfoValid(),
          pageName: "condition-info",
        },
        {
          title: "Photos",
          description: "Add photos of your car",
          isCompleted: this.isPhotoSectionCompleted(),
          pageName: "photo-upload",
        },
        {
          title: "Sale & contact info",
          description:
            "Details about selling your car and the best way to contact you",
          isCompleted: this.isSaleInfoValid(),
          pageName: "contact",
        },
      ];
    },
    mobileMediaQuery() {
      return window.matchMedia("(min-width: 768px)");
    },
    checkReadonly: function() {
      return this.authenticateData.listed || this.authenticateData.readonlyMode;
    },
    isNZ: function() {
      return parseInt(this.authenticateData.locid) === 2;
    },
    isActiveSellerCheckbox() {
      return this.isNZ || this.feesAgreementsCheckbox.active;
    },
    feesAgreementsCheckboxContent() {
      let result = this.feesAgreementsCheckbox.text;
      for (let i = 0; i < this.feesAgreementsCheckbox.links.length; i++) {
        const element = this.feesAgreementsCheckbox.links[i];
        result = result.replace(
          new RegExp('\\{' + i + '\\}', 'g'),
          `<a
              href="${element.link}"
              target="_blank"
              >${element.name}</a
            >`
        );
      }
      return result;
    },
    sellerTermsAndFeesError() {
      return this.authenticateData.leadSource !== 'lp007' && this.isActiveSellerCheckbox ? this.saleInfo.sellerTermsAndFees === false : false;
    },
    sellerCheckboxValidation() {
      return !this.isNZ && !this.feesAgreementsCheckbox.active
        ? true
        : this.saleInfo.sellerTermsAndFees === true
    },
    isShowMessages() {
      this.$nextTick(() => {
        let count = 0;
        const classesToCheck = ['navigation-err-msg', 'navigation-unsaved-msg'];
        for (const className of classesToCheck) {
          const hasClass = document.querySelector(`.${className}`);
          if (hasClass) {
            count += 1;
          }
        }
        if (count >= 2 && !this.listed) {
          this.isShowManyMessages = true;
          return true;
        } else if (count >= 2 && this.listed) {
          this.isShowManyListedMessages = true;
          return true;
        } else {
          this.isShowManyMessages = false;
          this.isShowManyListedMessages = false;
        }
      });
      return this.initValid && this.isSomePageNotValid && this.isShowErrMsg && this.isShowUnsavedMsg && !this.isFirstTimeVisitPage && !this.checkReadonly && this.listed;
    },
    isShowOneMessage() {
      return (this.initValid && this.isSomePageNotValid && this.isShowErrMsg) || 
        (this.isShowUnsavedMsg && !this.isFirstTimeVisitPage && !this.checkReadonly) ||
        (!this.isShowUnsavedMsg && !this.isFirstTimeVisitPage && this.isShowUnsavedConfirmMsg) ||
        this.listed
    },
    isShowSaveDraftMsg() {
      return this.isShowUnsavedMsg && !this.isFirstTimeVisitPage && !this.checkReadonly;
    },
    isShowConfirmMsg() {
      if (this.checkReadonly && !this.isShowUnsavedConfirmMsg) {
        return false;
      } else if (this.checkReadonly && this.onEdit && this.customerEmailCheckCondition && this.customerMobileCheckCondition && this.customerNameCheckCondition) {
        return true;
      } else if (!this.isShowUnsavedMsg && !this.isFirstTimeVisitPage && this.isShowUnsavedConfirmMsg && !this.checkReadonly) {
        return true;
      }
      return false;
    },
    mandatoryImagesCheck() {
      if (this.authenticateData.imageUrls.length === 0 && !this.guideCameraUsed) {
        for (const item of mandatoryList) {
          if (!this.uploadImage.find((image) => image.key === item.key)) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    customerNameCheckCondition() {
      return this.nameRegex.test(this.authenticateData.customerName) && this.authenticateData.customerName.length < 110 && this.customerFullNameValidCheck;
    },
    customerFullNameValidCheck() {
      const words = this.authenticateData.customerName.split(/\s+/);
      if (words.length < 2 || words.some(word => word.length < 2)) {
        return false;
      } else {
        return true;
      }
    },
    customerEmailCheckCondition() {
      return this.emailRegex.test(this.authenticateData.email) && this.authenticateData.email.length < 256;
    },
    customerMobileCheckCondition () {
      return this.isNZ
        ? this.customerMobileNZRuleCondition
        : this.authenticateData.customerMobile.startsWith("04") &&
          this.authenticateData.customerMobile.length === 10;
    },
    customerMobileNZRuleCondition () {
      return this.isNZ
        ? (this.authenticateData.customerMobile.startsWith("02") &&
          this.authenticateData.customerMobile.length >= 9 &&
          this.authenticateData.customerMobile.length <= 11) ||
          (this.authenticateData.customerMobile.startsWith("+642") &&
          this.authenticateData.customerMobile.length >= 10 &&
          this.authenticateData.customerMobile.length <= 12)
        : this.authenticateData.customerMobile.length === 10;
    },
    customerPostalCodeCheckCondition() {
      const locationDetails = locationValMap(this.authenticateData.customerPostalCode, this.isNZ);
      return this.isNZ ? locationDetails.state : (locationDetails.postCode && locationDetails.state);
    },
    activeBtnAction: function () {
      const checkboxesValidate =
        this.authenticateData.leadSource !== "lp007"
          ? this.sellerCheckboxValidation
          : true;
      const isInfoValid = this.pages
        .filter(page => page.pageName !== 'photo-upload')
        .every(page => page.isCompleted) && this.isPhotoUploadValid();
      return (
        !this.$v.saleInfo.$invalid &&
        checkboxesValidate &&
        isInfoValid &&
        !this.sellerTermsAndFeesError
      );
    },
    locationDetails() {
      return locationValMap(this.authenticateData.customerPostalCode, this.isNZ);
    },
    listingStatusTooltip() {
      switch (this.listingStatus) {
        case "Not listed":
          return "We're either waiting for more details/photos of your car or reviewing what you've already submitted. Please submit all the requested car details or photos if you haven't yet, so we can get your car listed.";
        case "Listing active":
          return "Your car is now listed for sale! We'll let you know when the listing ends.";
        default:
          return "Your listing has ended. We'll be in touch soon to go over the results with you.";
      }
    },
  },
  validations: {
    saleInfo: {
      sellerTermsAndFees: {
        required: requiredIf(function(){
          return !this.isNZ ? this.saleInfo.sellerTermsAndFees === true && this.feesAgreementsCheckbox.active : this.saleInfo.sellerTermsAndFees === true
        })
      },
    },
  },
  methods: {
    handleEditMobilePage: function (pageName) {
      if (this.isMobile && !this.isLoadingApi) {
        if (pageName === 'photo-upload') {
          if (this.uploadImage.length === 0) {
            this.$router.push({
              name: this.isSpecialDeviceBrowser ? "photo-upload" : "photo",
              query: this.$route.query
            }).catch((err) => {
              if (err) {
                if (this.isSpecialDeviceBrowser) {
                  this.$router.replace({ path: '/photo/upload', query: this.$route.query });
                }
                if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
                  Promise.reject(err)
                }
              }
            });
          } else {
            this.$router.push({
              name: "photo-upload",
              query: this.$route.query
            }).catch((err) => {
              if (err) {
                this.$router.replace({ path: '/photo/upload', query: this.$route.query });
                if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
                  this.$router.replace({ path: '/photo/upload', query: this.$route.query });
                  Promise.reject(err)
                }
              }
            });
          }
        } else {
          this.$router.push({ name: pageName, query: this.$route.query });
        }
      }
    },
    handleEditPage: function (pageName) {
      if (!this.isLoadingApi) {
        if (pageName === 'photo-upload') {
          if (this.uploadImage.length === 0) {
            this.$router.push({
              name: this.isSpecialDeviceBrowser ? "photo-upload" : "photo",
              query: this.$route.query
            }).catch((err) => {
              if (err) {
                if (this.isSpecialDeviceBrowser) {
                  this.$router.replace({ path: '/photo/upload', query: this.$route.query });
                }
                if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
                  Promise.reject(err)
                }
              }
            });
          } else {
            this.$router.push({
              name: "photo-upload",
              query: this.$route.query
            }).catch((err) => {
              if (err) {
                this.$router.replace({ path: '/photo/upload', query: this.$route.query });
                if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
                  this.$router.replace({ path: '/photo/upload', query: this.$route.query });
                  Promise.reject(err)
                }
              }
            });
          }
        } else {
          this.$router.push({ name: pageName, query: this.$route.query });
        }
      }
    },
    handleCheckAllComplete() {
      this.initValid = true;
      this.isSomePageNotValid = this.pages.some(page => !page.isCompleted && page.pageName !== 'photo-upload') || !this.isPhotoUploadValid();
      if (this.initValid && this.isSomePageNotValid && !this.checkReadonly) this.isShowErrMsg = true;
    },
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    handleCloseErrMsg() {
      this.isShowErrMsg = false;
    },
    handleCloseUnsavedMsg() {
      this.isShowUnsavedMsg = false;
    },
    handleCloseUnsavedConfirmMsg() {
      this.isShowUnsavedConfirmMsg = false;
    },
    handleBeforeUnload(event) {
      if (!this.isIdle) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    async saveOrUpdateTempData() {
      const ipAddress = await this.getClientIP();
      this.authenticateData.ipAddress = ipAddress;
      this.$store.dispatch(UPDATE_DATA, this.authenticateData);
    },
    async getWriteOffAndStolenStatus() {
      const status = await customerDetailService.getWriteOffAndStolenStatus({
        appraisalID: this.authenticateData.appraisalId,
      });
      if (status.data && status.data.some(item => item.WRITTEN_OFF_AND_STOLEN_CHECK === 'Y')) {
        this.writeOffAndStolenStatus = true;
      }
    },
    async getWriteOffAndStolen() {
      const { data } = await customerDetailService.getWriteOffAndStolen({
        rego: this.authenticateData.rego,
        state: this.authenticateData.carInfo.location
      });
      if (data) {
        this.$store.dispatch(SET_WRITTEN_OFF_AND_STOLEN, {
          writtenOff: data.writeOff ? "Yes" : "No",
          stolen: data.stolen ? "Yes" : "No",
          lookupFailures: data.err ? [data.err, data.responseUrl] : []
        });
      }
    },
    isHaveUnsavedChanges() {
      if ((this.pages.some(page => !page.isCompleted && page.pageName !== 'photo-upload') || !this.isPhotoUploadValid()) && this.onEditPage) {
        this.isShowUnsavedMsg = true;
        this.isShowUnsavedConfirmMsg = false;
      } else if (this.pages
        .filter(page => page.pageName !== 'photo-upload')
        .every(page => page.isCompleted) && 
        this.isPhotoUploadValid() && this.onEditPage) 
      {
        this.isShowUnsavedMsg = false;
        this.isShowUnsavedConfirmMsg = true;
      }
    },
    isFirstTimeOnPage() {
      // Check local storage for the flag
      const hasVisited = localStorage.getItem('hasVisitedPage');

      if (!hasVisited) {
        this.isFirstTimeVisitPage = true;
        localStorage.setItem('hasVisitedPage', 'true');
      } else {
        this.isFirstTimeVisitPage = false;
      }
    },
    isCarInfoValid () {
      let isNotValid = false;
      Object.keys(this.carInfo).forEach(key => {
        if (!this.carInfoWhiteList.includes(key) && this.carInfo[key] === '' && !this.isNZ) {
          isNotValid = true;
        } else if (!this.carInfoNZWhiteList.includes(key) && this.carInfo[key] === '' && this.isNZ) {
          isNotValid = true;
        }
      });
      return !isNotValid;
    },
    isConditionInfoValid() {
      return !Object.keys(this.conditionInfo).some(
        (key) =>
          (this.conditionInfo["serviceHistory"] === "Partial" &&
            this.conditionInfo["serviceHistoryNotes"] === "" &&
            !this.isNZ) ||
          (this.conditionInfo["warningLights"] === "Yes" &&
            this.conditionInfo["warningLightsDesc"] === "") ||
          (!this.conditionInfoWhiteList.includes(key) &&
            (key === "serviceHistoryDetail"
              ? this.conditionInfo["serviceHistory"] !== "None" &&
                this.conditionInfo[key] === ""
              : this.conditionInfo[key] === "") &&
            !this.isNZ) ||
          (!this.conditionInfoNZWhiteList.includes(key) &&
            this.conditionInfo[key] === "" &&
            this.isNZ)
      );
    },
    isPhotoUploadValid() {
      return this.authenticateData.imageUrls.length > 0 || this.uploadImage.length > 0;
    },
    isPhotoSectionCompleted() {
      return this.authenticateData.imageUrls.length >= 8 || this.uploadImage.length >= 8;
    },
    isSaleInfoValid() {
      let isNotValid = false;
      Object.keys(this.authenticateData).forEach(key => {
        if ((this.authenticateData['customerName'] !== '' && !this.customerNameCheckCondition) ||
          (this.authenticateData['email'] !== '' && !this.customerEmailCheckCondition) ||
          (this.authenticateData['customerMobile'] !== '' && !this.customerMobileCheckCondition) ||
          (this.authenticateData['customerPostalCode'] !== '' && !this.customerPostalCodeCheckCondition))
        {
          isNotValid = true;
        }

        if (this.personalInfoList.includes(key) && this.authenticateData[key] === '') {
          isNotValid = true;
        }
      });
      Object.keys(this.saleInfo).forEach(key => {
        if ((this.saleInfo['sell'] === 'Now' && this.saleInfo['pickUp'] === '') ||
          (this.saleInfo['sell'] === 'Later' && this.saleInfo['pickUpLater'] === '' && !this.isNZ) ||
          (this.saleInfo['reservePrice'].toString().length >= 3 && !this.saleInfo['productTier'] && this.isShowPackageList()) ||
          (this.saleInfo['reservePrice'].toString().length < 3 && this.isShowPackageList()))
        {
          isNotValid = true;
        }

        if (!this.saleInfoWhiteList.includes(key) && !this.saleInfo[key] && !this.isNZ) {
          isNotValid = true;
        } else if (!this.saleInfoNZWhiteList.includes(key) && !this.saleInfo[key] && this.isNZ) {
          isNotValid = true;
        }
      });
      return !isNotValid;
    },
    async getClientIP() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Error fetching client IP:', error);
      }
    },
    handleConfirm: async function () {
      this.handleCheckAllComplete();
      this.$v.saleInfo.$touch();
      if (this.sellerTermsAndFeesError && !this.checkReadonly) this.showTermsAndFeesMessage = true;
      if (this.checkReadonly) this.showDisableSubmitBtnTooltip = true;

      if (
        this.activeBtnAction &&
        !this.isLoadingApi &&
        (!this.checkReadonly || this.onEdit)
      ) {
        this.isLoadingApi = true;
        !this.isNZ && await this.getWriteOffAndStolenStatus();
        if (!this.isNZ && this.authenticateData.rego !== 'TEMPREGO' && this.authenticateData.rego !== '' && !this.writeOffAndStolenStatus) {
          await this.getWriteOffAndStolen();
        }
        await this.$store.dispatch(SET_SALE_INFORMATION, {
          ...this.saleInfo,
        });
        try {
          const { images, oldImages } = separatesUploadImages(this.uploadImage);
          const ipAddress = await this.getClientIP();
          const res = await LeadUpdateService.updateLead({
            ...this.authenticateData,
            conditionInfo: {
              ...this.conditionInfo,
              kms: removeFormatNumberWithComma(this.conditionInfo.kms),
              key: this.conditionInfo.key === '3 or more' ? '3' : this.conditionInfo.key,
              warningLightsDesc: this.conditionInfo.warningLights === 'Yes' ? this.conditionInfo.warningLightsDesc : '',
              serviceHistoryNotes: this.conditionInfo.serviceHistory === 'Partial' ? this.conditionInfo.serviceHistoryNotes : ''
            },
            images: this.$store.getters.uploadImage.filter(image => !Object.hasOwnProperty.call(image, "err") && !image.isAutoplay),
            oldImages,
            customerEmail: this.authenticateData.email,
            photoUploadActionLogs: {
              updatedAt: new Date().toISOString(),
              totalCount: {
                add: images.length,
                delete: 0
              }
            },
            usedGuidedCamera: this.takePhotoWithGuidedCamera,
            isWrittenOffAndStolen: this.writeOffAndStolenStatus,
            uxeId: this.uxeId,
            ipAddress,
          }, false)
          if (res.status === 201 && res.data) {
            this.isLoadingApi = false;
            eraseCookie("qs");
            this.$router.push({ name: "thanks-page" });
          } else {
            this.isLoadingApi = false;
          }
        } catch (err) {
          this.isLoadingApi = false;
          if (err.response && err.response.status === 422) {
            const errors = err.response.data.errors;
            Object.keys(errors).forEach(field => {
              this.$toast.clear();
              this.$toast.error(`${err.response.data.message}: ${errors[field][0]}`);
            });
          } else if (err.response) {
            this.$toast.clear();
            this.$toast.error(err.response.data.message);
          } else {
            logError(`Submission error: ${err}`);
          }
        }
      }
    },
    isShowPackageList() {
      return (
        !this.isNZ &&
        this.packages.every((pkg) =>
          pkg.state.includes(this.locationDetails.state)
        ) &&
        this.listingTiers &&
        !this.listingTiersExcludedLeadSourceList?.includes(LeadSourceStringMap[this.authenticateData.leadSource] || this.authenticateData.leadSource)
      );
    },
    showTooltip() {
      if (this.checkReadonly) this.showDisableSubmitBtnTooltip = true;
    },
    hideTooltip() {
      this.showDisableSubmitBtnTooltip = false;
    },
    showSellerTermTooltip() {
      if (this.isAgreed) this.isShowSellerTermTooltip = true;
    },
    hideSellerTermTooltip() {
      this.isShowSellerTermTooltip = false;
    }
  },
  watch: {
    "saleInfo.sellerTermsAndFees": function(val) {
      if (!val) this.showTermsAndFeesMessage = true;
      else this.showTermsAndFeesMessage = false;
      this.$store.dispatch('setOnEdit', true);
      this.$store.dispatch('setOnEditPage', true);
    },
    "saleInfo.marketingComm": function() {
      this.$store.dispatch('setOnEdit', true);
      this.$store.dispatch('setOnEditPage', true);
    },
  },
  mounted() {
    this.initValid = false;

    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;

    // Check is close tab
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    // Check is special device
    if (this.isMobile) {
      const userAgent = navigator.userAgent || window.opera; // Check user use in-app facebook or instagram browser android so can only upload
      const isiOSChromeBrowser = (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes(browserList);
      
      if (isiOSChromeBrowser) this.isSpecialDeviceBrowser = true;
    }

    this.isHaveUnsavedChanges();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  created() {
    if (this.saleInfo.sellerTermsAndFees) {
      this.isAgreed = true;
    }
    this.isFirstTimeOnPage();
  }
};
</script>
<style lang="scss">
.navigation-height {
  min-height: calc(100vh - 9px);
}
.navigation-listed-height {
  min-height: calc(100vh + 75px);
}
.navigation-full-height {
  min-height: calc(100vh + 45px);
}
.navigation-notify-height,
.navigation-many-listed-height {
  min-height: calc(100vh + 165px);
}
.navigation {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  .welcome-message {
    font-size: 20px;
    line-height: 28px;
    color: var(--primary-color);
    margin-bottom: 0 !important;
    p {
      margin-bottom: 0.5rem !important;
    }
  }
  .navigation-err-container {
    .navigation-err-msg {
      color: #B42318;
      background-color: #FEF3F2;
      border-radius: 8px;
      height: 68px;
      font-size: 14px;
    }
  }
  .navigation-unsaved-msg {
    color: #0041D1;
    background-color: #E9F1FF;
    border-radius: 8px;
    height: 68px;
    font-size: 14px;
    span {
      width: 300px;
    }
  }
  .listing-badge {
    width: max-content;
    color: #B54708;
    border: 1px solid #B54708;
    background-color: #FFFAEB;
    padding: 4px 10px 4px 20px;
    border-radius: 16px;
    font-size: 16px;
    position: relative;
    cursor: default;
    &.listing-active {
      color: #027A48;
      border-color: #027A48;
      background-color: #ECFDF3;
      &::before {
        background-color: #12B76A;
      }
    }
    &.listing-ended {
      color: #344054;
      border-color: #344054;
      background-color: #F2F4F7;
      &::before {
        background-color: #344054;
      }
    }
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #F79009;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
    }
    .listing-status-tooltip {
      position: absolute;
      top: 38px;
      left: 0;
      width: 250px;
      background-color: #2e312e;
      color: #f0f1ec;
      border-radius: 4px;
      padding: 4px 8px 4px 8px;
      font-family: "Avenir Next LT W05 Regular";
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      z-index: 999;
      display: none;
    }
    &:hover .listing-status-tooltip {
      display: block;
    }
  }
  .navigation-list {
    .navigation-item {
      cursor: pointer;
      padding-left: 30px;
      padding-right: 30px;
      height: 140px;
      margin-bottom: 20px;
      border: 1px solid #989898; 
      border-radius: 8px;
      box-shadow: 0px 4px 4px 0px #00000040;
      .navigation-edit-btn {
        background-color: #ffffff !important;
        outline: none !important;
        border: none;
        transform: scale(1.1);
      }
    }
    .navigation-item-uncompleted {
      border: solid 1px #B42318 !important;
    }
    .navigation-item-uncompleted-height {
      height: 108px !important;
    }
  }
  .navigation-list .navigation-item:last-child {
    height: 160px;
  }
  .navigation-list .navigation-item-uncompleted-height:last-child {
    height: 128px !important;
  }
  .navigation-report {
    width: 187px;
    height: 44px;
    border: 1px solid #989898;
    border-radius: 8px;
  }
  .seller-customer {
    color: #000000;
    width: 100%;
    padding: 0 5px;
    .seller-checkbox-err {
      color: red !important;
      font-style: italic;
      min-height: 16px;
      font-size: 12px;
      font-family: unset;
      margin: unset;
    }
    .seller-checkbox {
      a {
        text-decoration: underline;
        color: #000000;
      }
      input {
        accent-color: var(--primary-color);
      }
      .seller-checkbox-label {
        display: flex;
        align-items: center;
        position: relative;
        span {
          flex-shrink: 50;
        }
        .seller-term-tooltip {
          position: absolute;
          width: 325px;
          top: -90%;
          background-color: #2e312e;
          color: #f0f1ec;
          border-radius: 4px;
          padding: 4px 8px 4px 8px;
          font-family: "Avenir Next LT W05 Regular";
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          z-index: 999;
        }
      }
      .custom-control-input.is-invalid ~ .custom-control-label,
      .custom-control-input.is-valid ~ .custom-control-label {
        color: #000000 !important;
      }
      .custom-control-input[disabled] ~ .custom-control-label, 
      .custom-control-input:disabled ~ .custom-control-label {
        color: #000000 !important;
      }
      .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: #adb5bd !important;
      }
      .custom-control-input.is-invalid ~ .custom-control-label::before {
        border-color: red;
      }
      .msg-error {
        font-family: Avenir Next LT W05 Medium;
      }
    }
    input:checked ~ label::before {
      color: #ffffff;
      background-color: #ffffff !important;
      border-color: #ffffff !important;
    }
    .readonly-checkbox {
      path.check-icon {
        fill: #C4C4C4;
      }
    }
    input[type='checkbox']{
      position: relative;
      cursor: pointer;
      width: 21px !important;
      height: 21px !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      outline: 1px solid #bcbcbc;
      box-shadow: none;
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      background: transparent;
    }
    input[type='checkbox']:checked {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;
    }
    .seller-checkbox-text {
      margin: 10px 0 0 10px;
      font-size: 14px;
      font-family: Avenir Next LT W05 Regular;
    }
    a {
      color: #000000;
      text-decoration: underline;
    }
    span {
      margin: 10px 0 0 10px;
      font-size: 14px;
      font-family: Avenir Next LT W05 Regular;
    }
  }
  .navigation-info {
    .navigation-page-photos-tile {
      color: #545454;
      background-color: #EBEEF2;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }
}
@media screen and(min-width: 768px) {
  .navigation-height {
    min-height: calc(100vh + 160px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 252px) !important;
  }
  .navigation-notify-height,
  .navigation-many-listed-height {
    min-height: calc(100vh + 350px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 275px) !important;
  }
  .navigation {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    .navigation-err-container {
      .navigation-err-msg {
        height: 52px !important;
        font-size: 16px !important;
        span {
          width: unset !important;
        }
      }
    }
    .navigation-unsaved-msg {
      height: 52px !important;
      font-size: 16px !important;
      span {
        width: 90% !important;
      }
    }
    .listing-badge {
      font-size: 20px;
      padding: 1px 12px 1px 26px;
      &::before {
        left: 12px;
      }
    }
    .navigation-list {
      .navigation-item {
        cursor: default !important;
        height: 142px !important;
        padding-left: 47px !important;
        padding-right: 47px !important;
        .navigation-edit-btn {
          color: var(--primary-color) !important;
          width: 241px !important;
          height: 47px !important;
          border: 1px solid var(--primary-color) !important;
          border-radius: 4px !important;
          font-size: 18px !important;
          transform: unset !important;
        }
      }
    }
    .seller-customer {
      padding: 0 !important;
      width: 500px !important;
    }
    .seller-term-tooltip {
      width: 355px !important;
    }
  }
}
@media screen and(min-width: 600px) and(max-width: 768px) and(orientation: landscape){
  .navigation-height {
    min-height: calc(100vh + 600px) !important
  }
  .navigation-full-height {
    min-height: calc(100vh + 620px) !important;
  }
  .navigation-notify-height,
  .navigation-many-listed-height {
    min-height: calc(100vh + 750px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 650px) !important;
  }
}
@media screen and(min-width: 768px) and(max-width: 990px) {
  .navigation-info {
    width: 240px !important;
  }
  @media (orientation: landscape) {
    .navigation-height {
      min-height: calc(100vh + 700px) !important
    }
    .navigation-full-height {
      min-height: calc(100vh + 770px) !important;
    }
    .navigation-notify-height,
    .navigation-many-listed-height {
      min-height: calc(100vh + 890px) !important;
    }
    .navigation-listed-height {
      min-height: calc(100vh + 830px) !important;
    }
  }
}
@media screen and(min-width: 990px) and(max-width: 1280px) and(orientation: landscape) and(max-resolution: 100dpi){ 
  .navigation-height {
    min-height: calc(100vh + 450px) !important
  }
  .navigation-full-height {
    min-height: calc(100vh + 530px) !important;
  }
  .navigation-notify-height,
  .navigation-many-listed-height {
    min-height: calc(100vh + 640px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 600px) !important;
  }
}
@media screen and(max-width: 768px) {
  .container {
    max-width: unset !important;
  }
  .navigation-unsaved-container {
    z-index: 99999 !important;
    position: fixed !important;
    bottom: 70px !important;
    background-color: #ffffff !important;
    padding-top: 20px !important;
    width: 100% !important;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
    .navigation-unsaved-msg {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }
  }
}
@media screen and(max-width: 360px) {
  .navigation-err-msg {
    font-size: 12px !important;
    .navigation-err-text {
      span {
        width: 187px !important;
      }
    }
  }
  .navigation-unsaved-msg {
    font-size: 12px !important;
    span {
      padding-left: 4px !important;
    }
    img {
      padding-right: 4px !important;
    }
  }
}
@media screen and(max-width: 545px) {
  .navigation-err-msg {
    span {
      width: 200px !important;
      margin-left: 15px !important;
    }
  }
  .navigation-unsaved-msg {
    span {
      width: 268px !important;
    }
  }
}
@media screen and(max-width: 345px) {
  .navigation-height {
    min-height: calc(100vh + 600px) !important
  }
  .navigation-full-height {
    min-height: calc(100vh + 690px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 750px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 725px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 795px) !important;
  }
}
@media screen and(min-width: 345px) and(max-width: 389px) {
  .navigation-height {
    min-height: calc(100vh + 390px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 565px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 630px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 625px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 675px) !important;
  }
}
@media screen and(min-width: 390px) and(max-width: 400px) {
  .navigation-height {
    min-height: calc(100vh + 300px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 440px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 525px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 480px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 580px) !important;
  }
}
@media screen and(min-width: 400px) and(max-width: 460px) {
  .navigation-height {
    min-height: calc(100vh + 300px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 390px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 495px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 460px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 515px) !important;
  }
}
@media screen and(min-width: 460px) and(max-width: 545px) {
  .navigation-height {
    min-height: calc(100vh + 45px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 75px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 110px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 70px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 155px) !important;
  }
}
@media (min-width: 990px) and(min-resolution: 120dpi) and(max-resolution: 143dpi) {
  .navigation-height {
    min-height: calc(100vh + 380px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 450px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 520px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 570px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 470px) !important;
  }
}
@media (min-width: 990px) and(min-resolution: 144dpi) {
  .navigation-height {
    min-height: calc(100vh + 500px) !important;
  }
  .navigation-full-height {
    min-height: calc(100vh + 600px) !important;
  }
  .navigation-notify-height {
    min-height: calc(100vh + 660px) !important;
  }
  .navigation-many-listed-height {
    min-height: calc(100vh + 700px) !important;
  }
  .navigation-listed-height {
    min-height: calc(100vh + 620px) !important;
  }
}
@media screen and(max-width: 350px) {
  .navigation-page-photos-tile {
    p {
      font-size: 11px !important;
    }
  }
}
</style>